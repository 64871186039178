import _export from "../internals/export";
import _fails from "../internals/fails";
import _toIndexedObject from "../internals/to-indexed-object";
import _objectGetOwnPropertyDescriptor from "../internals/object-get-own-property-descriptor";
import _descriptors from "../internals/descriptors";
var $ = _export;
var fails = _fails;
var toIndexedObject = _toIndexedObject;
var nativeGetOwnPropertyDescriptor = _objectGetOwnPropertyDescriptor.f;
var DESCRIPTORS = _descriptors;
var FAILS_ON_PRIMITIVES = fails(function () {
  nativeGetOwnPropertyDescriptor(1);
});
var FORCED = !DESCRIPTORS || FAILS_ON_PRIMITIVES; // `Object.getOwnPropertyDescriptor` method
// https://tc39.es/ecma262/#sec-object.getownpropertydescriptor

$({
  target: "Object",
  stat: true,
  forced: FORCED,
  sham: !DESCRIPTORS
}, {
  getOwnPropertyDescriptor: function getOwnPropertyDescriptor(it, key) {
    return nativeGetOwnPropertyDescriptor(toIndexedObject(it), key);
  }
});
export default {};